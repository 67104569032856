.react-datepicker {
  font-family: inherit;
  border-radius: 0.5rem;
  border: 1px solid #e2e8f0;
  width: 100%;
  font-size: 0.875rem;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__header {
  background-color: #f8fafc;
  border-bottom: 1px solid #e2e8f0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__month {
  margin: 0.2rem;
}

.react-datepicker__day-name {
  color: #64748b;
  font-weight: 500;
  width: 1.8rem;
  margin: 0.1rem;
  font-size: 0.75rem;
}

.react-datepicker__day {
  width: 1.8rem;
  height: 1.8rem;
  line-height: 1.8rem;
  margin: 0.1rem;
  font-size: 0.75rem;
}

.react-datepicker__day--selected {
  background-color: #2563eb;
  border-radius: 50%;
}

.react-datepicker__day:hover {
  background-color: #e2e8f0;
  border-radius: 50%;
}

.highlight-date {
  background-color: rgba(37, 99, 235, 0.2);
  font-weight: bold;
  border-radius: 50%;
}